import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


const CustomPhoneInput = styled(PhoneInput)`
  .form-control {
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    background-color: #f1f2f2;
    margin-top: 16px;
    min-height: 48px;
    font-size: 16px;
    color: #76797a;
    font-weight: 400;
    width: 100%;
    
    @media (max-width: 991px) {
      padding: 14px 20px;
      width: 80%;
    }
  }
`;

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 8px;
`;

const FormWrapper = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FormContainer = styled.div`
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f1f2f2;
  background-color: #fcfcfd;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 65px 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FormHeader = styled.div`
  display: flex;
  width: 100%;
  max-width: 837px;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FormTitle = styled.h2`
  color: #313638;
  align-self: stretch;
  margin: auto 0;
  font: 600 40px Poppins, sans-serif;
`;

const RequiredText = styled.span`
  color: #e00;
  align-self: stretch;
  margin: auto 0;
  font: 400 16px/1.3 Inter, sans-serif;
`;

const FormDescription = styled.p`
  color: #313638;
  font: 300 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FormSection = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  gap: 24px;
  max-width: 837px;
  flex-direction: column;
  font-family: Inter, sans-serif;
  justify-content: flex-start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const InputGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Label = styled.label`
  color: #0d4a64;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
`;

const Input = styled.input`
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  background-color: #f1f2f2;
  margin-top: 16px;
  min-height: 48px;
  max-width: 837px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  color: #76797a;
  font-weight: 400;
  padding-left: 24px;
  
  @media (max-width: 991px) {
    padding: 14px 20px;
    width: 80%;
  }
`;

const TextArea = styled.textarea`
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  background-color: #f1f2f2;
  margin-top: 16px;
  min-height: 202px;
  width: 813px;
  padding-left: 24px;
  padding-top: 24px;
  font-family: Inter, sans-serif;
  font-size: 17px;
  color: #76797a;
  font-weight: 400;
  resize: vertical;
  
  @media (max-width: 991px) {
    padding: 20px;
    width: 80%;
  }
`;

const FileUpload = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: 100%;
  max-width: 100%;
  flex-direction: row;
  gap: 16px;
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  background-color: #f1f2f2;
  min-height: 48px;
  font-size: 16px;
  color: #313638;
  font-weight: 400;
  cursor: pointer;
  padding-right: 25px;
  padding-left: 15px;

  @media (max-width: 991px) {
    padding-right: 25px;
    padding-left: 15px;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 32px;
`;

const Checkbox = styled.input`
  width: 26px;
  height: 26px;
`;

const CheckboxLabel = styled.label`
  color: #000;
  font: 300 16px Poppins, sans-serif;
`;

const SubmitButton = styled.button`
  align-self: flex-start;
  border-radius: 32px;
  background-color: #91c46b;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 21px 48px;
  border: none;
  cursor: pointer;
  margin-top: 40px;
  
  @media (max-width: 991px) {
    padding: 21px 20px;
  }
`;

const FileName = styled.span`
  flex: 1;
  color: #313638;
  font-size: 16px;
  line-height: 48px;
`;

const IconImage = styled.img`
  width: 18px;
  height: 19px;
`;

const validationSchema = Yup.object({
  founderName: Yup.string().required('Founder Name is required'),
  startupName: Yup.string().required('startup Name is required'),
  startupWebsite: Yup.string().required('startup Website is required'),
  linkProvide: Yup.string().required('Provide link is required'),
  // address: Yup.string().required('Address is required'),
  phone: Yup.string().required('Phone number is required'),
  email: Yup.string().email('Invalid email address').required('Email Addressis required'),

  description: Yup.string().required('Description is required'),
  fileUpload: Yup.mixed()
    .required('File is required')
    .test(
      'fileFormat',
      'Unsupported file format. Only PDF, JPEG, PNG, DOC, PPT, and ZIP are allowed.',
      (value) => {
        if (!value) return false; // Return false if no file is uploaded
        console.log('Uploaded file type:', value.type); // Debugging line
        const supportedFormats = [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/zip',
          'application/x-zip-compressed'
        ];
        return supportedFormats.includes(value.type);
      }
    )

    .test(
      'fileSize',
      'File size must be less than or equal to 25MB',
      (value) => {
        if (!value) return false; // Return false if no file is uploaded
        return value.size <= 25 * 1024 * 1024; // 25MB in bytes
      }
    ),

    
});

const PitchForm = () => {
  const [fileName, setFileName] = useState('');
  const [formData, setFormData] = useState(null); // For fetched data

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/contact-form`);
        setFormData(response.data); // Adjust according to your API response structure
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    console.log('Form submitted'); // Debug log
    try {
      const formData = new FormData();
      formData.append('founderName', values.founderName);
      formData.append('startupName', values.startupName);
      formData.append('startupWebsite', values.startupWebsite);
      formData.append('linkProvide', values.linkProvide);
      // formData.append('address', values.address);
      formData.append('phone', values.phone);
      formData.append('email', values.email);
   
      formData.append('description', values.description);
      if (values.fileUpload) {
        formData.append('fileUpload', values.fileUpload);
      }

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/contact-form`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        toast.success('Thank you for your submission!');
        resetForm();
        setFileName(''); // Reset the file name after submission
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      toast.error(`Submission failed: ${error.message}`);
      console.error('Error details:', error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={{
          founderName:'',
          email: '',
          phone: '',
          startupName: '',
          startupWebsite:'',
          description: '',
          linkProvide:'',
          fileUpload: null,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <FormWrapper>
            <FormContainer>
              <Form>
                <FormHeader>
                  <HeaderContent>
                    <FormTitle>Pitch to us</FormTitle>
                    <RequiredText>*Required</RequiredText>
                  </HeaderContent>
                  <FormDescription>
                  Please provide your contact information below and attach your business plan. 
                  </FormDescription>
                </FormHeader>
                <FormSection>
                  <InputGroup>
                    <Label htmlFor="founderName">Founder Name <RequiredText>*</RequiredText></Label>
                    <Field name="founderName" as={Input} />
                    <ErrorMessage name="founderName" component={ErrorText} />
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="email">Email Address <RequiredText>*</RequiredText></Label>
                    <Field name="email" as={Input} type="email" />
                    <ErrorMessage name="email" component={ErrorText} />
                  </InputGroup>

                  {/* <InputGroup>
                    <Label htmlFor="address">Address <RequiredText>*</RequiredText></Label>
                    <Field name="address" as={Input} />
                    <ErrorMessage name="address" component={ErrorText} />
                  </InputGroup> */}

                  <InputGroup>
                    <Label htmlFor="phone">Phone <RequiredText>*</RequiredText></Label>
                    <CustomPhoneInput
                      country={'us'}
                      value={values.phone}
                      onChange={(phone) => setFieldValue('phone', phone)}
                    />
                    <ErrorMessage name="phone" component={ErrorText} />
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="startupName">Startup Name <RequiredText>*</RequiredText></Label>
                    <Field name="startupName" as={Input} />
                    <ErrorMessage name="startupName" component={ErrorText} />
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="startupWebsite">Startup Website <RequiredText>*</RequiredText></Label>
                    <Field name="startupWebsite" as={Input} />
                    <ErrorMessage name="startupWebsite" component={ErrorText} />
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="description">Description <RequiredText>*</RequiredText></Label>
                    <Field name="description" as={TextArea} />
                    <ErrorMessage name="description" component={ErrorText} />
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="linkProvide">Provide Link <RequiredText>*</RequiredText></Label>
                    <Field name="linkProvide" as={Input} />
                    <ErrorMessage name="linkProvide" component={ErrorText} />
                  </InputGroup>

                  

                  <InputGroup>
                    <Label htmlFor="fileUpload">Upload your business plan</Label>
                    <FileUpload>
                      <FileInput
                        id="fileUpload"
                        name="fileUpload"
                        type="file" 
                         accept=".pdf, .jpeg, .jpg, .png, .doc, .docx, .ppt, .pptx, .zip" // Add this line
                        onChange={(event) => {
                          const file = event.target.files[0];
                          setFieldValue('fileUpload', file);
                          setFileName(file ? file.name : ''); // Set file name or clear if no file
                        }}
                      />
                      <FileLabel htmlFor="fileUpload">
                        <IconImage src="./Icon.png" alt="Upload Icon" />Choose File
                      </FileLabel>
                      <FileName>{fileName}</FileName>
                      {/* <ErrorMessage name="fileUpload" component={ErrorText} /> */}
                    </FileUpload>
                    <ErrorMessage name="fileUpload" component={ErrorText} style={{ marginTop: '0.5rem' }} />
                  </InputGroup>

                  <SubmitButton type="submit">Submit</SubmitButton>
                </FormSection>
              </Form>
            </FormContainer>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};

export default PitchForm;
